import React from 'react';
import './freetail.css'; // Adjust the path as necessary

function App() {
  return (
    <div>
      <head>
        <title>Freetail</title>
      </head>
      <body>
        <div className="flex-container">
          <header>
            <h1>FREETAIL</h1>
            <p>At Freetail we are building exclusive consumer-tech products targeted at India's 5 Million+ young iOS users.</p>
            <p className="project-link">Check out our first Project at <a href="http://www.coffeeapp.in">www.coffeeapp.in</a></p>
          </header>
        </div>
        <footer>
          <p>© 2024 FREETAIL TECHNOLOGIES PRIVATE LIMITED. All Rights Reserved.</p>
          <p>Contact info: saharsh@freetail.in</p>
          <p>Phone: +91 8697469301</p>
        </footer>
      </body>
    </div>
  );
}

export default App;
